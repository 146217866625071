import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import BlogLayout from "../../layouts/BlogLayout";

const IndexPage = (props) => {
  const postList = props.data.allMarkdownRemark;

  return (
    <BlogLayout>
      <div className="blog-list-container">
        <h1>Blog</h1>
        {postList.edges.map(({ node }, i) => (
          <Link key={i} to={node.fields.slug} className="link">
            <div className="post-list flex flex-nowrap flex-center">
              <div className="post-thumbnail">
                <Img fixed={node.frontmatter.image.childImageSharp.fixed} />
              </div>
              <div>
                <h2 className="post-title">{node.frontmatter.title}</h2>
                <h2 className="post-infos">
                  Publié le {node.frontmatter.date} par{" "}
                  {node.frontmatter.author}
                </h2>
                <p className="post-excerpt">{node.excerpt}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </BlogLayout>
  );
};

export default IndexPage;

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            author
            date(formatString: "DD-MM-YYYY")
            title
            image {
              childImageSharp {
                fixed(width: 220, height: 220) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
